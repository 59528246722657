import { ObjectId } from 'bson'
import moment from 'moment'
import { pickBy } from 'lodash-es'
import { mustGetDB } from '../setup'
import { differences } from '~/shared/diff'
import type BaseModel from '~/models/base'
import type { TimeableType } from '~/shared/time'
import type { UserSession } from '~/store/acl'

export type SaveDataType = TimeableType & {
  _id?: string | ObjectId
  // uid?: string// user
  _ownerId?: string
  _teams?: string[]
  createdBy?: string
  updatedBy?: string
}

export enum PrimaryTypeEnum {
  ObjectId = 'ObjectId',
  UID = 'uid',
}

export interface SaveOption {
  byPassValidate?: boolean
  doNotAssignOwnerId?: boolean
  doNotAssignTeams?: boolean
  primaryField?: string
  primaryType?: PrimaryTypeEnum
  forceUpdate?: boolean
}

export interface RootStateType {
  session: {
    currentUser: {
      id: string
    }
  }
}

export const dataSaveMany = async <T extends SaveDataType>(
  currentUser: UserSession,
  model: BaseModel,
  data: T[],
  options: SaveOption = {},
): Promise<(Partial<T> | null)[]> => {
  const primaryField = options.primaryField ?? model.primaryField
  const primaryType = options.primaryType ?? model.primaryType

  const saveData: [updating: Partial<T> | null, oriRecord: T][] = []
  for (let c = 0; c < data.length; c++) {
    const [updating, oriRecord] = await prepareSaveData<T>(
      currentUser,
      model,
      data[c],
      options,
    )
    saveData.push([updating, oriRecord])
  }
  if (!options.byPassValidate) {
    for (let c = 0; c < saveData.length; c++) {
      const [updating, oriRecord] = saveData[c]
      const isNew
        = !oriRecord[primaryField] || oriRecord[primaryField] === 'new'

      if (!isNew && updating === null && !options.forceUpdate) {
        continue
      }
      const fullData = { ...oriRecord, ...updating }
      try {
        await dataCheckSingle(
          model,
          isNew ? SaveType.insert : SaveType.update,
          fullData,
          options,
        )
        // const fullData = { ...oriRecord, ...updating }
        if (model.onBeforeValidate) {
          await model.onBeforeValidate(currentUser, updating, oriRecord)
        }
      }
 catch (err: any) {
        console.error(
          'check',
          model.label || model.table,
          c,
          err.message,
          updating,
        )
        throw new Error(
          `${model.label || model.table} (${c + 1}) error: ${err.message}`,
        )
      }
    } // each data

    // TODO check if any of the data in the list have duplicate by unique or same primary field value
  } // no byPassValidate

  const res: (Partial<T> | null)[] = []
  for (let c = 0; c < saveData.length; c++) {
    const [updating, oriRecord] = saveData[c]
    try {
      const fullData = { ...oriRecord, ...updating }
      const primayValue = fullData[options.primaryField || model.primaryField]
      console.log(
        'attempting save',
        model.label || model.table,
        c,
        primayValue?.toString(),
        updating,
      )

      // is update and nothing to update, then skip
      if (primayValue && updating === null && !options.forceUpdate) {
        console.log(
          'skip save',
          model.label || model.table,
          c,
          primayValue?.toString(),
          updating,
        )
        res.push(null)
        continue
      }
      console.log(
        'not skip save',
        model.label || model.table,
        c,
        primayValue?.toString(),
        updating,
      )
      const saveRes = await dataSaveSingle(currentUser, model, fullData, {
        ...options,
        byPassValidate: true,
      })

      res.push(saveRes)
    }
 catch (err: any) {
      console.error(
        'Save',
        model.label || model.table,
        c,
        err.message,
        updating,
      )
      throw new Error(
        `${model.label || model.table} (${c + 1}) error: ${err.message}`,
      )
    }
  } // each data

  return res
}

export const prepareSaveData = async <T extends SaveDataType>(
  currentUser: UserSession,
  model: BaseModel,
  data: Partial<T>,
  options: SaveOption = {},
): Promise<[Partial<T> | null, T]> => {
  const db = await mustGetDB()
  const tableName: string = model.table
  const tableLabel: string = model.label || tableName
  // console.log('dataSaveSingle', tableName, data)
  const collection = db.collection(tableName)

  const primaryField = options.primaryField ?? model.primaryField
  const primaryType = options.primaryType ?? model.primaryType
  const isNew = !data[primaryField] || data[primaryField] === 'new'

  let oriRecord: any = {}
  console.log('isnew?', isNew, data[primaryField])
  if (!isNew) {
    console.log('finding existing to update', data[primaryField])
    const primaryLookupVal
      = primaryType === PrimaryTypeEnum.ObjectId
        ? new ObjectId(data[primaryField])
        : data[primaryField]
    const cond = { [primaryField]: primaryLookupVal }
    let record = await collection.findOne(cond)
    if (record === null) {
      console.error(`${tableLabel} by ${primaryField} missing`, cond)
      throw new Error(`${tableLabel} missing`)
    }

    oriRecord = { ...record }
  }

  let updating: Partial<T> = { ...data }
  Object.keys(model.relation).forEach((relationName) => {
    updating[relationName] = undefined
  })
  let now = moment().toDate()
  console.log('currentUser', currentUser)
  // console.log('ori updated', Object.assign({}, updated))
  updating = pickBy(
    updating,
    (value, key) =>
      data.hasOwnProperty(key)
      && key !== 'created_at'
      && key !== 'updated_at'
      && key !== 'createdAt'
      && key !== 'updatedAt'
      && key !== primaryField,
  )

  // ensure datatype is correct
  for (let key in updating) {
    let value = updating[key]
    if (value === null) {
      continue
    }
    if (value instanceof Date) {
      continue
    }
    // console.log('field', key, typeof data[key])
  }

  if (isNew) {
    updating.createdBy = currentUser?.uid
    if (!options?.doNotAssignOwnerId && !updating._ownerId) {
      updating._ownerId = currentUser?.uid
    }
    if (!options?.doNotAssignTeams && !updating._teams) {
      updating._teams = currentUser?.customData?.teams;
    }

    updating.createdAt = now
    // updating._region = 'my'
  }
 else {
    if (!options?.doNotAssignTeams && !updating._teams) {
      updating._teams = currentUser?.customData?.teams;
    }
 else {
      console.log('not assigning team')
    }
  }
  updating.updatedAt = now
  updating.updatedBy = currentUser?.uid

  if (!isNew) {
    // check if nothing is updated
    const updateFields = Object.keys(updating).filter(
      field =>
        ![
          'updatedAt',
          'updatedBy',
          'createdAt',
          'createdBy',
          '_ownerId',
        ].includes(field),
    )
    const changedFields = [] as string[]
    updateFields.forEach((field) => {
      console.log(
        'checking',
        field,
        typeof updating[field],
        typeof oriRecord[field],
      )
      // skip check for relation removed field
      if (
        typeof updating[field] === 'undefined'
        && typeof oriRecord[field] === 'undefined'
      ) {
        return
      }
      // TODO may work with nested values
      if (typeof updating[field] !== typeof oriRecord[field]) {
        changedFields.push(field)
        return
      }

      if (
        typeof updating[field] === 'string'
        && updating[field] !== oriRecord[field]
      ) {
        changedFields.push(field)
        return
      }

      if (updating[field] === null && oriRecord[field] === null) {
        return
      }

      if (updating[field] === null && oriRecord[field] !== null) {
        changedFields.push(field)
        return
      }
      // console.log('final check', field, JSON.stringify(updating[field]), JSON.stringify(oriRecord[field]))
      if (JSON.stringify(updating[field]) !== JSON.stringify(oriRecord[field])) {
        changedFields.push(field)
      }
    })

    if (changedFields.length === 0) {
      console.log('no change fields?', changedFields)
      return [null, oriRecord]
    }
  }
  // console.log('updating?', updating, oriRecord)
  return [updating, oriRecord] as [Partial<T>, T]
}
/*
  does partial update based on given data fields only
*/
export const dataSaveSingle = async <T extends SaveDataType>(
  currentUser,
  model: BaseModel,
  data: Partial<T>,
  options: SaveOption = {},
): Promise<T | null> => {
  const db = await mustGetDB()
  const tableName: string = model.table
  const tableLabel: string = model.label || tableName
  // console.log('dataSaveSingle', tableName, data)
  const collection = db.collection(tableName)

  let [updating, oriRecord] = await prepareSaveData<T>(
    currentUser,
    model,
    data,
    options,
  )
  const primaryField = options.primaryField ?? model.primaryField
  const primaryType = options.primaryType ?? model.primaryType
  const isNew = !data[primaryField] || data[primaryField] === 'new'
  const saveType = isNew ? SaveType.insert : SaveType.update

  const fullData = { ...oriRecord, ...updating }
  if (!options.byPassValidate) {
    await dataCheckSingle(model, saveType, fullData, options)
  }
  if (model.onBeforeValidate) {
    await model.onBeforeValidate(currentUser, updating, oriRecord)
  }

  console.log('dataSaveSingle final', updating)

  if (options.byPassValidate) {
    await dataCheckExists(model, saveType, fullData, options)
  }

  if (model.onBefore) {
    if ((await model.onBefore(currentUser, updating, oriRecord)) === false) {
      console.log('returned false onBefore')
      return null
    }
  }

  // console.log('saving', updating, Object.keys(updating).length)
  if (updating !== null && Object.keys(updating).length > 0) {
    // console.log('changed field', updated)
    // const beforeUpdate = {...updating}
    updating = parseFormForServer(updating!)
    // console.log('datasaveSingle parseFormForServer?', updating)
    try {
      if (!isNew) {
        // const ownerId = data._ownerId ? data._ownerId : updating._ownerId
        // updating = { ...differences(updating, oriRecord), _ownerId: ownerId }
        updating = differences<T>(updating!, oriRecord)

        console.log('update', data[primaryField], updating)
        const updateId
          = typeof data[primaryField] === 'string' && primaryType === 'ObjectId'
            ? new ObjectId(data[primaryField])
            : data[primaryField]
        let res = await collection.updateOne(
          { [primaryField]: updateId },
          { $set: updating },
        )
        // console.log('res', res)
      }
 else {
        console.log('insert', updating)
        const res = await collection.insertOne(updating)
        // console.log('res', res)
        data[primaryField] = res.insertedId
      }
    }
 catch (err: any) {
      console.error('error saving', model.label || model.table, err)
      throw new Error(`Save error ${err.message}`)
    }
  }
 else {
    console.log('not saving', model.label || model.table)
  }

  const updated = await collection.findOne({
    [primaryField]: new ObjectId(data[primaryField]),
  })
  console.log('saved', updated)

  if (model.onAfter) {
    if ((await model.onAfter(currentUser, updating, oriRecord)) === false) {
      console.log('returned false onAfter')
    }
  }

  return updated
}

export interface CheckOption {
  required: string[] // for both update and new
  insertRequired?: string[] // for only new
  updateRequired?: string[] // for only update
}

export interface CheckType {
  _id?: string | ObjectId
}

export enum SaveType {
  insert = 'insert',
  update = 'update',
}

export const dataCheckExists = async (
  model: BaseModel,
  saveType: SaveType,
  data: CheckType,
  options: SaveOption = {},
): Promise<void> => {
  const db = await mustGetDB()
  const tableName: string = model.table
  const tableLabel: string = model.label || tableName
  // console.log('dataSaveSingle', tableName, data)
  const collection = db.collection(tableName)
  const primaryField = options.primaryField ?? model.primaryField
  const primaryType = options.primaryType ?? model.primaryType
  const isNew = !data[primaryField] || data[primaryField] === 'new'

  if (model.uniques) {
    for (let u = 0; u < model.uniques.length; u++) {
      const names = model.uniques[u] ?? []
      const findCond: Record<string, any> = {}
      for (let n = 0; n < names.length; n++) {
        const name = names[n]
        const val = data[name]
        const fieldSchema = model.fields[name]
        const fieldLabel = fieldSchema?.label || name

        console.log('where', name, JSON.stringify(data[name]), data)
        if (val === undefined) {
          throw new Error(`${fieldLabel} is not set but required for unique`)
        }

        findCond[name] = data[name]
      }

      const existings = await collection.find(findCond)
      if (isNew && existings.length > 0) {
        console.error('record exists', model.label || model.table, findCond)
        throw new Error('Record exists')
      }
      console.log('uid?', data[primaryField])
      for (let c = 0; c < existings.length; c++) {
        const existing = existings[c]
        // console.log(
        //   'checking existing',
        //   existing[primaryField],
        //   data[primaryField]
        // )
        if (
          data[primaryField]
          && existing[primaryField].toString() !== data[primaryField].toString()
        ) {
          console.log('found existing', existing, data[primaryField], data)
          throw new Error('Record exists')
        }
        //  else if (data.uid && existing.uid !== data.uid) {
        //   console.log('found existing by uid', existing, data[primaryField], updating)
        //   throw new Error('Record exists')
        // }
      }
    } // loop uniques
  } // has unique
}

export const dataCheckSingle = async (
  model: BaseModel,
  saveType: SaveType,
  data: CheckType,
  options: SaveOption = {},
): Promise<boolean> => {
  let required = [
    ...model.required,
    ...(saveType === SaveType.insert
      ? model.insertRequired || []
      : model.updateRequired || []),
  ]
  for (let i in required) {
    const field: string = required[i]
    const fieldSchema = model.fields[field]
    const fieldLabel = fieldSchema?.label || field
    // console.log(field)
    if (data[field] === undefined) {
      console.log(model.label || model.table, 'missing', fieldLabel, data)
      throw new Error(`${fieldLabel} is required`)
    }

    if (data[field] === null) {
      const fieldSchema = model.fields[field]
      if (fieldSchema) {
        if (fieldSchema.nullable === undefined || !fieldSchema.nullable) {
          throw new Error(`${fieldLabel} cannot be null`)
        }
      }
    }
    if (data[field] === '') {
      console.log(model.label || model.table, 'is required', fieldLabel, data)
      throw new Error(`${fieldLabel} is required`)
    }
  }
  if (model.fields) {
    const fields = Object.keys(model.fields)
    for (let c = 0; c < fields.length; c++) {
      const fieldName = fields[c]
      const field = model.fields[fieldName]
      const val = data[fieldName]
      const fieldType = field.type
      const fieldLabel = field?.label || fieldName

      if (val === undefined || val === null) {
        continue
      }
      switch (fieldType) {
        case 'money':
          if (val === '') {
            continue
          }
          const checkVal = parseFloat(val)
          if (isNaN(checkVal)) {
            console.log(
              'datachecksingle',
              model.label || model.table,
              `${fieldLabel} is not a ${fieldType}`,
              val,
            )
            throw new TypeError(`${fieldLabel} is not a ${fieldType}`)
          }
          break

        case 'number':
          if (val === '') {
            continue
          }
          const checkVal4 = Number(val)
          if (isNaN(checkVal4)) {
            console.log(
              'datachecksingle',
              model.label || model.table,
              `${fieldLabel} is not a ${fieldType}`,
              val,
            )
            throw new TypeError(`${fieldLabel} is not a ${fieldType}`)
          }
          break

        case 'float':
          if (val === '') {
            continue
          }
          const checkVal2 = parseFloat(val)
          if (isNaN(checkVal2)) {
            console.log(
              'datachecksingle',
              model.label || model.table,
              `${fieldLabel} is not a ${fieldType}`,
              val,
            )
            throw new TypeError(`${fieldLabel} is not a ${fieldType}`)
          }
          break
        case 'int':
          if (val === '') {
            continue
          }
          const checkVal3 = parseInt(val, 10)
          if (isNaN(checkVal3)) {
            console.log(
              'datachecksingle',
              model.label || model.table,
              `${fieldLabel} is not a ${fieldType}`,
              val,
            )
            throw new TypeError(`${fieldLabel} is not a ${fieldType}`)
          }
          break

        case 'boolean':
          if (typeof val !== 'boolean') {
            console.log(
              'datachecksingle',
              model.label || model.table,
              `${fieldLabel} is not a ${fieldType}`,
              val,
            )
            throw new TypeError(`${fieldLabel} is not a ${fieldType}`)
          }
          break

        case 'objectId':
          if (typeof val !== 'object') {
            console.log(
              'datachecksingle',
              model.label || model.table,
              `${fieldLabel} is not a ${fieldType}`,
              val,
            )
            throw new TypeError(`${fieldLabel} is not a ${fieldType}`)
          }

          if (!ObjectId.isValid(val)) {
            console.log(
              'datachecksingle',
              model.label || model.table,
              `${fieldLabel} is not a ${fieldType}`,
              val,
              val.toString(),
            )
            throw new TypeError(`${fieldLabel} is not a ${fieldType}`)
          }

          break

        default:
      }
    }
  }

  await dataCheckExists(model, saveType, data, options)

  return true
}
