import type { TablePaginationConfig } from 'ant-design-vue'
import { dataPreProcess } from './get'
import { callFunc } from '~/realm/data/call'
import { pagination } from '~/shared/general'
import { ModelRelationType } from '~/models/base'
import type BaseModel from '~/models/base'
import type { ModelRelationFieldType } from '~/models/base'
// import { getRealm, mustGetDB } from '../setup'

export enum Sort {
  Asc = 'Asc',
  Desc = 'Desc',
}
export interface SortParam {
  field: string
  dir: string
}

// export interface fetchInclude {
//   model: BaseModel
//   localField: any
//   foreignField: any
// }

export interface fetchIncludeObject {
  include: fetchInclude
}

export interface fetchInclude {
  [key: string]: boolean | fetchIncludeObject
}
export interface fetchListParam<T> {
  page: number
  limit: number
  sorts: SortParam[]
  filters?: Record<string, any>
  onRow?: (row: any, index: number) => Promise<T>
  include?: fetchInclude
}

export interface ListFetchEvent {
  fetchParam: fetchListParam<any>
  pagination: TablePaginationConfig
  filters?: Record<string, any>
  result: fetchListResult
}

export interface fetchListResult {
  list: any[]
  page: number
  limit: number
  count: number
}

interface ListParam {
  page?: number
  limit?: number
  sorts?: SortParam[]
  filters?: Record<string, any>
  startAfter?: any
}

interface UpdateParam {
  name: string
  value: [string, number, object]
}

// export const getCacheName = <T>(
//   model: BaseModel,
//   p: fetchListParam<T>) => {
//   return `${model.table || model.label}_${p.page}_${p.limit}_${JSON.stringify(p.filters)}_${JSON.stringify(p.sorts)}_${JSON.stringify(p.include)}`;
// }

// export const getCachedList = async <T>(model: BaseModel,
//   p: fetchListParam<T>) => {
//   const cacheName = getCacheName(model, p)
//   if (typeof window === 'undefined' || !window.localStorage.cachedList) {
//     return null
//   }
//   const cachedList = JSON.parse(window.localStorage.cachedList)
//   console.log('getCachedList', cacheName, cachedList)
//   if (cachedList[cacheName] && (new Date(cachedList[cacheName].expires)).getTime() >= Date.now()) {
//     return cachedList[cacheName].data
//   }
//   return null
// }

// export const setCacheList = async <T>(
//   model: BaseModel,
//   p: fetchListParam<T>, res: any) => {
//   if (typeof window === 'undefined') return;
//   const cacheName = getCacheName<T>(model, p)
//   const strCache = window.localStorage.cachedList || '{}'
//   const cachedList = JSON.parse(strCache)
//   const cacheStore = JSON.stringify({ expires: Date.now() + 1000 * 60, data: res });
//   console.log('setCacheList', cacheName, cacheStore)
// }

export async function cacheFunction<T extends (...args: any[]) => any>(
  fn: T,
  options: {
    cacheKey: (...args: Parameters<T>) => string | null,
    ttl?: number
  }
): T {
  if (typeof window === 'undefined') {
    return fn
  }
  const { cache } = await import('./cache.server')
  return ((...args: Parameters<T>): ReturnType<T> => {
    const key = options.cacheKey(...args)
    if (key === null)  {
      return fn(...args)
    }
    
    const cachedResult = cache.get(key)
    
    if (cachedResult !== undefined) {
      console.log('cache hit', key)
      return cachedResult as ReturnType<T>
    }

    const result = fn(...args)
    console.log('cacheFunction set', key, result)
    cache.set(key, result, options.ttl ?? 1000 * 60 * 5)
    
    return result
  }) as T
}

export async function invalidateCache(modelName: string): void {
  const { cache } = await import('./cache.server')
  const keysToInvalidate: string[] = [];

  cache.forEach((value, key) => {
    if (key.startsWith(modelName)) {
      keysToInvalidate.push(key);
    }
  });

  for (const key of keysToInvalidate) {
    cache.del(key);
  }
  console.log(`Invalidated ${keysToInvalidate.length} cache entries for model: ${modelName}`);
}

/**
 * fetch list
 * @param table either tablename as string or { funcName: fetchXXX }
 * @param p
 * @returns
 */
export const dataFetchList = async <T>(
    model: BaseModel,
    p: fetchListParam<T>,
  ): Promise<fetchListResult> => {
  const limit = (p.limit ? p.limit : pagination.limit) || 20
  const page = p.page || 1
  const offset = (page - 1) * limit
  const table = model.table
  const sorts: Record<string, -1 | 1> = {};
  for (let s = 0; s < p.sorts.length; s++) {
    const sort = p.sorts[s];
    sorts[sort.field] = sort.dir && sort.dir === 'desc' ? -1 : 1;
  }
  const match: any = p.filters || {};
  if (p.filters?.search) {
    match.$text = p.filters.search.$text;
    match.search = undefined;
  }

  const lookups: any[] = [];
  const nestedRelation = [] as {
    asName: string;
    relation: ModelRelationFieldType;
    include?: fetchInclude;
  }[];

  if (p.include) {
    const keys = Object.keys(p.include);

    for (let k = 0; k < keys.length; k++) {
      const asName = keys[k];

      if (p.include[asName]) {
        const relation = model.relation[asName];
        if (!relation) {
          throw new Error(`relation ${asName} of ${model.label || model.table} not found`);
        }

        const lookup: any = {
          from: relation.model.table,
          localField: relation.foreignKey,
          as: asName,
        };
        if (relation.targetKey) {
          lookup.foreignField = relation.targetKey;
        }

        let childInclude = {};
        if (typeof p.include[asName] === 'object') {
          childInclude = (p.include[asName] as fetchIncludeObject).include;
        }
        nestedRelation.push({ asName, relation, include: childInclude });
        // continue;
      }
    }
  }

  const agg = [
    { $match: match },
    ...lookups,
    ...(Object.keys(sorts).length > 0 ? [{ $sort: sorts }] : []),
    { $skip: offset },
    { $limit: limit },
  ];
  const result = (await callFunc('fetchList', table, agg)) as {
    list: any[];
    count: number;
  };

  const res = result.list;
  const count = result.count;
  const list: any[] = [];
  const relationValues = {} as any;
  if (nestedRelation.length > 0) {
    const nestedPromises = [] as Promise<any>[];
    for (let r = 0; r < nestedRelation.length; r++) {
      const relation = nestedRelation[r];
      const values = res
        .map((row: any) => {
          return row[relation.relation.foreignKey];
        })
        .filter((v) => v !== undefined);

      if (values.length === 0) {
        relationValues[relation.asName] = [];
        continue;
      }

      const fetchParam = {
        page: 1,
        limit: 99999,
        sorts: [],
        filters: {},
        include: relation.include,
      };

      fetchParam.filters[relation.relation?.targetKey as string] = {
        $in: values.reduce((acc: [], v: []) => {
          if (Array.isArray(v)) {
            return acc.concat(v.filter((vv) => acc.findIndex((a) => JSON.stringify(a) === JSON.stringify(vv)) < 0));
          }
          return acc.concat(acc.findIndex((a) => JSON.stringify(a) === JSON.stringify(v)) < 0 ? [v] : []);
        }, []),
      };
      nestedPromises.push(
        dataFetchList(relation.relation?.model as BaseModel, fetchParam).then(
          (res) => {
            relationValues[relation.asName] = res.list
            return res.list
          }
        )
      );
    }

    await Promise.all(nestedPromises);
  }

  for (let c = 0; c < res.length; c++) {
    let record = res[c];
    const id = record._id.toString();

    if (nestedRelation.length > 0) {
      for (let r = 0; r < nestedRelation.length; r++) {
        const relation = nestedRelation[r];
        const localField = relation.relation?.foreignKey as string;
        const [localFieldTable, localFieldField] = localField.includes('.') ? localField.split('.') : [localField, ''];
        const targetField = relation.relation?.targetKey as string;
        const values = record[localFieldTable];

        if (Array.isArray(values)) {
          record[relation.asName] = [];
          for (let v = 0; v < values.length; v++) {
            const valueRow = values[v];
            console.log(
              'finding variant of',
              relation.asName,
              valueRow,
              'relation list',
              relationValues[relation.asName]
            );
            const foundRows = relationValues[relation.asName].filter((rRow) => {
              return rRow[targetField].toString() === valueRow.toString();
            });
            console.log('found hasmany rows', relation.asName, foundRows);
            record[relation.asName].push(...foundRows);
          }
        } else {
          const foundRows = relationValues[relation.asName].filter((rRow) => {
            if (typeof record[localFieldTable] === 'undefined') {
              return undefined;
            }
            if (Array.isArray(rRow[targetField])) {
              return rRow[targetField].some(
                (relationFieldValue) => relationFieldValue.toString() === record[localFieldTable].toString()
              );
            }
            return rRow[targetField].toString() === record[localFieldTable].toString();
          });

          record[relation.asName] = foundRows;
        }
      }
    }
    let row = { ...record };
    if (p.include) {
      const keys = Object.keys(p.include);

      for (let k = 0; k < keys.length; k++) {
        const asName = keys[k];
        const relation = model.relation[asName];
        if (!relation) {
          throw new Error(`relation ${asName} of ${model.label || model.table} not found`);
        }
        const relationType = relation.type;
        if ([ModelRelationType.BelongsTo, ModelRelationType.OneToOne].includes(relationType)) {
          row[asName] = row[asName] && row[asName].length > 0 ? row[asName][0] : null;
        }
      }
    }
    row = await dataPreProcess(model, record.uid, { ...row });
    if (p.onRow) {
      row = p.onRow(row, c);
    }
    list.push(row);
  }

  return {
    list,
    page,
    limit,
    count,
  }
}

export const cachableDataFetchList = async () => cacheFunction(
  dataFetchList,
  {
    cacheKey: (model, p) => ['Category', 'Product'].includes(model.table) ? `${model.table}-${JSON.stringify({ p })}` : null,
    ttl: 5 * 60 * 1000, // 5 minutes
  }
);
