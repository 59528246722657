import { ObjectId } from 'bson'
import moment from 'moment'
import { dataFetchList } from './list'
import type { SortParam, fetchInclude } from './list'
import { ModelRelationType } from '~/models/base'
import type BaseModel from '~/models/base'

export interface fetchOptionType {
  include?: {
    [relationTable: string]: boolean
  }
}

export type fetchSingleOptionType<T> = fetchOptionType & {
  onRow?: (row: any, index: number) => Promise<T>
  sorts: SortParam[]
  include?: Record<string, fetchInclude | boolean>
}

const defaultSorts = [{ field: '_id', dir: 'desc' }]

export const dataFetchSingleWhere = async <T>(
  model: BaseModel,
  filters: Record<string, any>,
  defaultValue: T | null = null,
  options: fetchSingleOptionType<T> = { sorts: defaultSorts },
): Promise<T | null> => {
  const res = await dataFetchList<T>(model, {
    page: 1,
    limit: 1,
    filters,
    ...options,
  })

  if (res.count > 0) {
    return res.list[0]
  }
  return defaultValue
}

export const dataFetchSingle = async <T>(
  model: BaseModel,
  id: string | ObjectId,
  defaultValue: T | null = null,
  options: fetchOptionType = {},
): Promise<T | null> => {
  if (typeof id === 'string' && (id === 'new' || !id)) {
    // console.log('dataFetchSingle', model.table, id)
    return defaultValue
  }

  const objId = typeof id === 'string' && id !== 'new' ? new ObjectId(id) : id

  const res = await dataFetchList<T>(model, {
    page: 1,
    limit: 1,
    filters: { _id: objId },
    ...options,
    sorts: defaultSorts,
  })
  if (res.count > 0) {
    return res.list[0]
  }
  return defaultValue
}

export const dataPreProcess = async <T>(
  model: BaseModel,
  id: string,
  data: T extends Record<string, any> ? T : Record<string, any>,
): Promise<T> => {
  const keys = Object.keys(data)
  const res = {} as T
  for (let c = 0; c < keys.length; c++) {
    const field = keys[c]
    let val = data[field]

    if (field === '_id') {
      val = data[field] // .toString()
    }

    // if (val !== null && val instanceof Date) {
    //   // console.log('isdate', field, val)
    //   val = moment(val)
    // }
    const relationField = model.relation?.[field]
    if (relationField) {
      // is relation
      if (
        [ModelRelationType.OneToOne, ModelRelationType.ManyToOne].includes(
          relationField.type,
        )
      ) {
        // is 1 parent or 1 to 1
        val = val?.[0]
      }
    }

    res[field] = val
  }
  return res
}
